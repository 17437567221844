<template>
  <b-card>
    <div v-show="!loading.first">
      <b-row class="mb-2">
        <b-col>
        <h1>Respuestas predeterminadas</h1>
        </b-col>
        <b-col class="text-right">
        <b-button v-if="permission.create" @click="openModalAnswer()" class="btn-check" variant="outline-warning" :title="$t('Crear Respuesta')">
          {{ $t('Crear Respuesta') }}
        </b-button>
        </b-col>
      </b-row>
      <form-render :form.sync="form" :fields="fieldsForm" @send="filterRequired" :buttonSend="buttonSend">
      </form-render>
      <div class="table--container">
        <table-render id="table-default-answer" :schema="schema" :rows="rows" :actions ="actions">

        </table-render>
      <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
      </div>
    </div>
    <div v-show="loading.first">
      <b-skeleton  type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
      <div class="table-render-skeleton">
      <b-skeleton-table
              :rows="pagination.limit || 10"
              :columns="schema.length || 10"
              :table-props="{ }"/>
      </div>
    </div>
  <NewAnswer :countryAnswer="country" :dataEdit="dataEdit" :updateData.sync="updateData" :dataPermission="permission"></NewAnswer>
  <DeleteAnswer :dataDelete="dataEdit" :deleteAnswer.sync="deleteData"></DeleteAnswer>
  </b-card>
</template>
<script>
import NewAnswer from './component/NewAnswer.vue'
import DeleteAnswer from './component/DeleteAnswer.vue'
import BaseServices from '@/store/services/index'
import { BFormTags, BButtonGroup, BButton, BSpinner, BContainer, BRow, BCol, BBadge } from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    NewAnswer,
    DeleteAnswer
  },
  data() {
    return {
      loading: {
        first: true
      },
      controlHeight: { class: 'row mb-12 spacing-label-field' },
      form: {},
      country: JSON.parse(JSON.stringify([])),
      fieldsForm: [],
      schema: [],
      rows: [],
      buttonSend: {icon: 'SearchIcon', color: 'warning', title: 'Buscar', text: 'Buscar', class: 'mt-2'},
      baseService: new BaseServices(this),
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        noShowDigits: false,
        calculateCountPage: true
      },
      actions: [],
      updateData: {},
      deleteData: false,
      dataEdit: null,
      permission: {
        create: false,
        edit: false,
        delete: false
      }
    }
  },
  mounted () {
    this.getDefaultAnswer()
    this.setDataPermissions()
  },
  methods: {
    filterRequired(dataSend) {
      this.getAnswers()
    },
    getDefaultAnswer() {
      this.loading.first = true
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = this.setQueryParams()

      const arrServices = [
        {
          name: 'getCountries'
        },
        {
          name: 'getDefaultAnswer',
          queryParams: {
            ...queryParams
          },
          params: {
            ...params
          }
        }
      ]
      this.baseService.callMultipleServices(arrServices, true)
        .then(response => {
          if (response.getCountries.data) this.setCountries(response.getCountries.data)
          if (response.getDefaultAnswer.data) {
            this.setDataAnswer(response.getDefaultAnswer.data, response.getDefaultAnswer.pageInfo ?? {})
          } 
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          this.loading.first = false
          this.setFields()
        })
    },
    getAnswers () {
      this.loading.first = true
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = this.setQueryParams()
      this.baseService.callService('getDefaultAnswer', queryParams, params)
        .then(response => {
          if (response.data) {
            this.setDataAnswer(response.data, response.pageInfo ?? {})
          } 
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          this.loading.first = false
          this.setFields()
        })
    },
    openModalAnswer(id) {
      this.dataEdit = id ? this.rows.filter(element => element.id === id)[0] : null
      this.$bvModal.show('modalNesAnswer')
    },
    openModalDelete(id) {
      if (this.permission.delete) {
        this.dataEdit = id ? this.rows.filter(element => element.id === id)[0] : null
        this.$bvModal.show('modalDeleteAnswer')
      } else {
        this.$alert('No tienes permiso para eliminar respuestas predeterminadas')
      }
    },
    setCountries (dataCountry) {
      this.country = dataCountry.map(element => 
        ({
          id: element.id,
          code: element.code,
          text: element.name
        }))
    },
    setDataAnswer (dataAnswer, dataPage = {}) {
      this.rows = [...dataAnswer]
      this.pagination.total = dataPage?.totalCount ?? 0
      this.pagination.page = dataPage?.currentPage ?? 1
    },
    setDataPermissions() {
      const permissionArray = this.$session.get('cas_user').special_permissions
      this.permission = {
        create: !!permissionArray.find((element) => element === 'create-default-answer'),
        edit: !!permissionArray.find((element) => element === 'edit-default-answer'),
        delete: !!permissionArray.find((element) => element === 'delete-default-answer')
      }
    },
    setFields () {
      this.fieldsForm = [{ fieldType: 'FieldInput', name: 'search', label: 'Nombre/Código/Palabra Clave', clearable: true},
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-sm-12 container-info col-md-6', options: this.country, clearable: true}
      ]
      this.actions = [
        {action: id => this.openModalAnswer(id), icon: 'EditIcon', color:'warning', text: 'Editar respuesta'},
        {action: id => this.openModalDelete(id), icon: 'TrashIcon', color:'warning', text: 'Eliminar respuesta'}
      ]
      this.schema = [
        {label: 'Nombre', key: 'name'},
        {label: 'Código', key: 'code_name'},
        {label: 'País', key: 'country'},
        {label: 'Mensaje', key: 'message'},
        {label: 'Acciones', key: 'actions', class: ['text-center thead--light']}
      ]
    },
    setQueryParams () {
      return {
        search: this.form.search ?? '',
        country: this.form.country?.code ?? '',
        page: this.pagination.page,
        limit: this.pagination.limit
      }
    }
  },
  watch: {
    updateData: {
      handler() {
        if (this.updateData) {
          this.form = {}
          this.getAnswers()
        }
      },
      deep: true
    },
    deleteData: {
      handler() {
        if (this.deleteData) {
          this.form = {}
          this.getAnswers()
        }
      },
      deep: true
    },
    'form.country'() {
      this.getAnswers()
    },
    'pagination.page'() {
      this.getAnswers()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getAnswers()
    }
  }
}
</script>